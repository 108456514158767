import React from "react";
import { Link } from "gatsby";
import { Container, Col, Row } from "reactstrap";

import Layout from "../components/layout";
import RequestForm from "../components/request-form";

const SecondPage = () => (
  <Layout title="Prop Maker - Request an Estimate">
    <Container className="text-center my-5">
      <h1>REQUEST AN ESTIMATE</h1>
      <p className="my-5">
        Tell us about your project and we'll try to get back to you within 24
        hours.
      </p>
      <Row>
        <Col lg={{ size: 6, offset: 3 }} md={{ size: 8, offset: 2 }}>
          <RequestForm />
        </Col>
      </Row>
    </Container>
  </Layout>
);

export default SecondPage;
