import "./css/header.css";
import { Link } from "gatsby";
import React from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  Row,
  Col,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container
} from "reactstrap";
import Navigation from "./Navigation";
import logo from "../images/logo.png";
import GatsbyLink from "gatsby-link";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const Header = () => (
  <StaticQuery
    query={graphql`
      query {
        logo: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <div className="header container">
        <Row>
          <Col lg="5" md="12">
            <Link to="/">
              <Img
                fluid={data.logo.childImageSharp.fluid}
                className="main-logo"
              />
            </Link>
          </Col>
          <Col lg="7" md="12">
            <a href="tel:1-818-357-1824">
              <h2 className="header-phone-number">818.738.7391</h2>
            </a>
            <Navigation />
          </Col>
        </Row>
      </div>
    )}
  />
);

export default Header;
