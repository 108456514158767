import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import icon from "./favicon.png";

import "./css/layout.css";
import "./css/bootstrap.min.css";

import Header from "./header";
import Footer from "./footer";

const Layout = props => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={data => (
      <>
        <div id="main-app">
          <Helmet
            title={props.title || data.site.siteMetadata.title}
            meta={[
              {
                name: "description",
                content: data.site.siteMetadata.description
              },
              {
                name: "keywords",
                content: "Props, trade show, movies, custom prop, prop maker"
              }
            ]}
            link={[
              { rel: "shortcut icon", type: "image/png", href: `${icon}` }
            ]}
          >
            <html lang="en" />
          </Helmet>
          <Header siteTitle={data.site.siteMetadata.title} />
          <div style={{ flex: 1 }}>{props.children}</div>
          <Footer />
        </div>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
