import "./css/request-form.css";
import React from "react";
import { Button, FormControl, Form, FormGroup } from "react-bootstrap";

const RequestForm = () => (
  <div className="request-form-component">
    <h4>
      REQUEST A FREE <br />
      ESTIMATE FOR YOUR PROJECT
    </h4>
    <Form name="contact" method="post" data-netlify="true" action="/success">
      <input type="hidden" name="form-name" value="contact" />
      <FormGroup className="field">
        <FormControl
          type="text"
          name="name"
          id="exampleName"
          placeholder="Name"
        />
      </FormGroup>
      <FormGroup className="field">
        <FormControl
          type="email"
          name="email"
          id="emailInput"
          placeholder="Email"
        />
      </FormGroup>
      <FormGroup className="field">
        <FormControl
          type="phone"
          name="phone"
          id="phoneInput"
          placeholder="Phone Number"
        />
      </FormGroup>

      <FormGroup className="field">
        <FormControl
          as="textarea"
          name="text"
          id="messageInput"
          placeholder="Message"
          rows={4}
        />
      </FormGroup>

      <Button type="submit" className="form-submit">
        SEND
      </Button>
    </Form>
  </div>
);

export default RequestForm;
