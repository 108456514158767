import "./css/footer.css";
import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import logo from "../images/logo.png";
import footerLogo from "../images/footer-logo.png";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        footerLogo: file(relativePath: { eq: "footer-logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <div className="footer-component">
        <div className="container footer-top">
          <Row>
            <Col md="5">
              <Img fluid={data.footerLogo.childImageSharp.fluid} />
            </Col>
            <Col md="7" />
          </Row>
        </div>
        <div className="container-flex footer-lower-copyright">
          <p>© PROPMAKER. ALL RIGHTS RESERVED.</p>
        </div>
      </div>
    )}
  />
);

export default Footer;
